import { createSlice } from '@reduxjs/toolkit';
import { fetchLoadAnnulmentOtherBranch } from './ActionAnnulmentSlice';

const initialState = {
  isLoading: false,
  annulmentsData: null,
  error: null
};

export const OtherBranchAnnulmentSlice = createSlice({
  name: 'annulmentOtherBranch',
  initialState,
  reducers: {
    onReset: (state /* action */) => {
      state.isLoading = false;
      state.annulmentsData = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoadAnnulmentOtherBranch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadAnnulmentOtherBranch.fulfilled, (state, { payload }) => {
      state.annulmentsData = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchLoadAnnulmentOtherBranch.rejected, (state, { error }) => {
      state.error = error;
      state.isLoading = false;
      state.annulmentsData = null;
    });
  }
});

// Action creators are generated for each case reducer function
export const { onReset } = OtherBranchAnnulmentSlice.actions;
