import './LibertyFooter.scss';

const LibertyFooter = () => {
  return (
    <footer className="libertyFooter">
      <span className="text item_name">
        HDI Seguros Colombia S.A. {new Date().getFullYear()}. Todos los derechos reservados
      </span>
    </footer>
  );
};

export default LibertyFooter;
