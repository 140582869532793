import { Fragment, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import TriangleWhiteUp from 'assets/icons/TriangleWhiteUp.svg';

//*icons
import notificationIcon from 'assets/icons/notification.svg';
import bell_no_notification from 'assets/icons/notification.svg';

import { useNotifications } from './context';
import styles from './Notifications.module.scss';
import { NotificationsProvider } from './context/index';
import { TabsNotificationTypes } from './constants';
import { Notification } from 'components/basics/Notification';

const LibertyNotifications = () => {
  const containerRef = useRef(null);

  const {
    notifications,
    notificationsCount,
    open,
    setOpen,
    readNotificationsExcel,
    readNotificationsMassively,
    totalDownload,
    totalRenovations,
    setTabSelected,
    tabSelected
  } = useNotifications();

  const total = notifications.length;

  const handleOutsideClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <button
        className={styles.icon}
        type="button"
        onClick={() => {
          setOpen(!open);
          setTabSelected(TabsNotificationTypes.DOWNLOAD_EXCEL);
          readNotificationsExcel();
        }}
      >
        <img src={notificationIcon} alt="icon" />
        {!!notificationsCount && notificationsCount > 0 && (
          <div className={styles.count}>{notificationsCount}</div>
        )}
      </button>
      <div className={styles.notifications} style={{ display: open ? 'block' : 'none' }}>
        <div className={styles.triangle_up}>
          <img src={TriangleWhiteUp} alt="TriangleWhiteUp" />
        </div>
        <div className={styles.header_tabs}>
          <span className={styles.title}>Notificaciones</span>
          <div className={styles.tabs}>
            <label
              className={`${styles.tab} ${
                tabSelected == TabsNotificationTypes.DOWNLOAD_EXCEL && styles.active
              }`}
              onClick={() => {
                setTabSelected(TabsNotificationTypes.DOWNLOAD_EXCEL);
                readNotificationsExcel();
              }}
            >
              <span className={styles.tab_title}>Descarga de documentos</span>
              {totalDownload > 0 && <div className={styles.total_ticket}>{totalDownload}</div>}
            </label>
            <label
              className={`${styles.tab} ${
                tabSelected == TabsNotificationTypes.RENOVATIONS && styles.active
              }`}
              onClick={() => {
                setTabSelected(TabsNotificationTypes.RENOVATIONS);
                readNotificationsMassively();
              }}
            >
              <span className={styles.tab_title}>Renovaciones</span>
              {totalRenovations > 0 && (
                <div className={styles.total_ticket}>{totalRenovations}</div>
              )}
            </label>
          </div>
        </div>
        <div className={styles.panel}>
          {notifications.length === 0 && (
            <div className={styles.no_notification_container}>
              <img
                className={styles.bell_no_notification}
                src={bell_no_notification}
                alt="bell_no_notification"
              />
              <span className={styles.no_notification}>¡Aún no tienes notificaciones!</span>
            </div>
          )}

          <Grid container>
            {notifications.map((notification, index) => (
              <Fragment key={index}>
                <Notification notification={notification} />
                {index !== total - 1 && (
                  <Grid item className={styles.rootDivider}>
                    <div className={styles.divider} />
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default () => (
  <NotificationsProvider>
    <LibertyNotifications />
  </NotificationsProvider>
);
