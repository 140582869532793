import userIcon from 'assets/icons/userHDI.svg';

import arrowIcon from 'assets/icons/arrow-down-small-gray.svg';
import logoIcon from 'assets/icons/hdiLogo.svg';

import Notifications from 'components/collections/Notifications';
import LibertyButton from '../LibertyButton/LibertyButton';

import { useSession } from 'context/SessionContext';
import Container from 'components/Container';

import { dateToString } from 'utils/formatDate';

import styles from './LibertyHeader.module.scss';
import { LibertyProfileInfo } from '../LibertyProfileInfo/LibertyProfileInfo';
import { useEffect, useRef, useState } from 'react';
import LibertyProfileMenuResponsive from '../LibertyProfileMenuResponsive/LibertyProfileMenuResponsive';

const LibertyHeader = ({ user = null }) => {
  const arrowRef = useRef(null);
  const userIconRef = useRef(null);
  const { logout } = useSession();
  const [openInfoUser, setOpenInfoUser] = useState(false);
  const dateToday = new Date();
  useEffect(() => {
    const closeInfoUser = (e) => {
      if (
        !e.composedPath().includes(arrowRef.current) &&
        !e.composedPath().includes(userIconRef.current)
      ) {
        setOpenInfoUser(false);
      }
    };
    document.body.addEventListener('click', closeInfoUser);
    return () => {
      document.body.removeEventListener('click', closeInfoUser);
    };
  }, []);

  return (
    <nav className={`liberty__container ${styles.libertyHeader}`}>
      <Container>
        <div className={styles.liberty_actions_header}>
          <div className={styles.logoBackground}>
            <img className={styles.logo} src={logoIcon} alt="Liberty" />
          </div>
          <div className={styles.profile}>
            <div className={styles.img}>
              <img
                src={arrowIcon}
                alt="arrow"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenInfoUser(!openInfoUser)}
                ref={arrowRef}
              />
            </div>
            <span className={styles.username}>{user?.name || ''}</span>
            <div className={styles.icon_desktop}>
              {user && (
                <LibertyProfileInfo
                  name={user?.name}
                  email={user?.email}
                  ip={user.ip}
                  dateToday={dateToString(dateToday)}
                  lastConnection={user?.last_session}
                  open={openInfoUser}
                >
                  <img
                    src={userIcon}
                    alt="user"
                    ref={userIconRef}
                    onClick={() => setOpenInfoUser(!openInfoUser)}
                  />
                </LibertyProfileInfo>
              )}
            </div>
            <Notifications />
            <LibertyProfileMenuResponsive
              name={user?.name}
              email={user?.email}
              ip={user.ip}
              dateToday={dateToString(dateToday)}
              lastConnection={user?.last_session}
            ></LibertyProfileMenuResponsive>
            <div className={styles.Button}>
              <LibertyButton
                id="btn_cerrarsesion"
                type="secondary"
                style={{ marginRight: '0', width: '122', height: '36px' }}
                onClick={logout}
              >
                Cerrar sesión
              </LibertyButton>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default LibertyHeader;
