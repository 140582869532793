import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import arrowRight from 'assets/icons/arrowRight.svg';

import {
  currentAndPreviousMonthRange,
  subMonths,
  addMonths,
  initDateContainerRange,
  firstDateOfTheMonth,
  getNumDaysMonth,
  isEqual,
  getMonthById,
  formatDate,
  isBefore,
  createArrayDays
} from '../utils';

import styles from './LibertyCustomCalendar.module.scss';
import { TooltipCalendar } from '../SubComponents/TooltipCalendar';

export const LibertyCustomRangesCalendar = ({
  onChange,
  maxDate = null,
  minDate = null,
  children,
  selected,
  margin = '0px',
  width = 'max-content',
  top = '-170px',
  left = '-40%',
  pastTooltip = 'No es posible seleccionar una fecha en el pasado.',
  futureTooltip = 'No es posible seleccionar una fecha superior a 30 días a partir de hoy.'
}) => {
  const calendarRef = useRef();
  const cancelBtnRef = useRef();
  const okBtnRef = useRef();
  const childrenRef = useRef();

  const [isShow, setIsShow] = useState(false);

  const [fromTo, setFromTo] = useState('desde');

  const [dateContainer, setDateContainer] = useState(initDateContainerRange(minDate));
  const [date, setDate] = useState(currentAndPreviousMonthRange(minDate));

  const addMonth = () => {
    const { currentMonth, previousMonth } = date;
    setDate({
      currentMonth: addMonths(currentMonth, 1),
      previousMonth: addMonths(previousMonth, 1)
    });
  };

  const subtractMonth = () => {
    const { currentMonth, previousMonth } = date;
    setDate({
      currentMonth: subMonths(currentMonth, 1),
      previousMonth: subMonths(previousMonth, 1)
    });
  };

  const chooseDate = () => {
    if (!dateContainer?.selectedDateEnd) return '';
    onChange?.({ from: dateContainer?.selectedDateInit, to: dateContainer?.selectedDateEnd });
    setIsShow(false);
    setDateContainer({
      ...dateContainer,
      currentDateInit: dateContainer?.selectedDateInit,
      currentDateEnd: dateContainer?.selectedDateEnd
    });
  };

  const validDate = useCallback(
    (dateIn) => {
      const comparedDate = dateIn.getTime();
      const topDate = maxDate instanceof Date ? maxDate.getTime() : null;
      const bottomDate = minDate instanceof Date ? minDate.getTime() : null;

      if (topDate && topDate - comparedDate < 0) {
        return false;
      } else if (bottomDate && comparedDate - bottomDate < 0) {
        return false;
      } else {
        return true;
      }
    },
    [maxDate, minDate]
  );

  const changeDate = (dateDay) => {
    if (fromTo == 'desde' && validDate(dateDay)) {
      setDateContainer({
        ...dateContainer,
        selectedDateInit: dateDay,
        selectedDateEnd: null
      });
      setDate({
        currentMonth: dateDay,
        previousMonth: subMonths(dateDay, 1)
      });
    }
    if (fromTo == 'hasta' && validDate(dateDay) && validRangeDate(dateDay)) {
      setDateContainer({
        ...dateContainer,
        selectedDateEnd: dateDay
      });
      setDate({
        currentMonth: dateDay,
        previousMonth: subMonths(dateDay, 1)
      });
    }
  };

  const openCloseSelect = (e) => {
    if (
      e.composedPath().find((element) => element === calendarRef.current) &&
      e.target !== cancelBtnRef.current &&
      e.target !== okBtnRef.current
    ) {
      if (!isShow) {
        const currentDateSelected =
          selected?.from && selected?.to
            ? { currentDateInit: selected.from, currentDateEnd: selected.to }
            : initDateContainerRange(minDate);
        setDateContainer({
          ...dateContainer,
          selectedDateInit: currentDateSelected.currentDateInit,
          selectedDateEnd: currentDateSelected.currentDateEnd
        });
        setDate({
          currentMonth: currentDateSelected.currentDateInit,
          previousMonth: subMonths(currentDateSelected.currentDateInit, 1)
        });
        setIsShow(true);
        setFromTo('desde');
      }
    } else {
      setIsShow(false);
    }
  };

  const validRangeDate = (dateIn) => dateIn >= dateContainer?.selectedDateInit;

  const validOrInvalidStyle = useCallback(
    (comparedDate) => {
      if (!comparedDate) return '';
      return validDate(comparedDate) &&
        !isBefore(firstDateOfTheMonth(date.currentMonth), comparedDate)
        ? styles.valid_date
        : styles.invalid_date;
    },
    [date.currentMonth, validDate]
  );

  const selectedDayStyle = useCallback(
    (comparedDate) => {
      if (!comparedDate) return '';
      let dateToCompared = null;
      if (fromTo == 'desde') {
        dateToCompared = dateContainer.selectedDateInit;
      } else if (fromTo == 'hasta') {
        dateToCompared = dateContainer.selectedDateEnd;
      }

      if (!dateToCompared) return '';

      if (isEqual(dateToCompared, comparedDate)) {
        return styles.selected;
      }

      return '';
    },
    [dateContainer?.selectedDateInit, dateContainer?.selectedDateEnd, fromTo]
  );

  const styleDays = (dateOfDate) => {
    if (selectedDayStyle(dateOfDate))
      return `${styles.days} ${validOrInvalidStyle(dateOfDate)} ${selectedDayStyle(dateOfDate)}`;
    else
      return `${styles.days} ${validOrInvalidStyle(dateOfDate)} ${paintRange(
        dateOfDate
      )} ${selectedDayStyle(dateOfDate)}`;
  };

  const paintRange = (dateOfDate) => {
    if (
      (!dateContainer?.selectedDateEnd && +dateOfDate == +dateContainer.selectedDateInit) ||
      (+dateOfDate >= +dateContainer.selectedDateInit &&
        +dateOfDate <= +dateContainer.selectedDateEnd)
    ) {
      return styles.paint_range;
    }
    return '';
  };

  const arrayDays = useMemo(() => {
    if (!date) return [];
    const { currentMonth, previousMonth } = date;
    let tempDays = [];
    //*Cantidad de días que tiene el mes actual
    const numDaysCurrentMonth = getNumDaysMonth(currentMonth);
    //*Cantidad de días que tiene el mes anterior
    const numDaysPreviousMonth = getNumDaysMonth(previousMonth);
    //*El mes actual inicia un domingo ?
    const firstDayOfWeakOfCurrentMonth = firstDateOfTheMonth(currentMonth).getDay();

    if (firstDayOfWeakOfCurrentMonth != 0) {
      tempDays = [
        ...createArrayDays(
          numDaysPreviousMonth - firstDayOfWeakOfCurrentMonth + 1,
          numDaysPreviousMonth,
          previousMonth,
          minDate,
          maxDate,
          { pastTooltip, futureTooltip }
        )
      ];
    }
    tempDays = [
      ...tempDays,
      ...createArrayDays(1, numDaysCurrentMonth, currentMonth, minDate, maxDate, {
        pastTooltip,
        futureTooltip
      })
    ];
    return tempDays;
  }, [date?.currentMonth, date?.previousMonth, pastTooltip, futureTooltip]);

  const dayMonthHeader = useMemo(() => {
    if (!dateContainer) return '';
    const { selectedDateInit, selectedDateEnd } = dateContainer;
    let finalDate = '';
    if (selectedDateInit) {
      finalDate = formatDate(selectedDateInit);
    }
    if (selectedDateEnd && +selectedDateEnd != +selectedDateInit) {
      finalDate += ' - ' + formatDate(selectedDateEnd);
    }
    return finalDate;
  }, [dateContainer.selectedDateInit, dateContainer.selectedDateEnd]);

  useEffect(() => {
    document.body.addEventListener('click', openCloseSelect);
    return () => {
      document.body.removeEventListener('click', openCloseSelect);
    };
  }, [isShow, selected]);

  const yearHeader = useMemo(() => {
    if (!dateContainer) {
      return '';
    }
    const { selectedDateInit, selectedDateEnd } = dateContainer;

    if (!selectedDateEnd) {
      return selectedDateInit?.getFullYear();
    }
    if (selectedDateInit?.getFullYear() === selectedDateEnd?.getFullYear()) {
      return selectedDateInit?.getFullYear();
    }
    return `${selectedDateInit?.getFullYear()} - ${selectedDateEnd?.getFullYear()}`;
  }, [dateContainer?.selectedDateInit, dateContainer?.selectedDateEnd]);

  return (
    <div
      ref={calendarRef}
      style={{
        height: 'max-content',
        width,
        position: 'relative',
        zIndex: isShow ? '1000' : '0',
        margin
      }}
    >
      <div ref={childrenRef} style={{ width: '100%' }}>
        {children}
      </div>
      <div
        className={styles.calendar}
        style={{
          display: isShow ? 'flex' : 'none',
          top,
          left
        }}
      >
        <div className={styles.header_calendar}>
          <div className={styles.container_years_months}>
            <span className={styles.year_calendar}>{yearHeader}</span>
            <span className={styles.day_month_calendar}>{dayMonthHeader}</span>
          </div>
          <div className={styles.buttons_container}>
            <button
              className={styles.header_button}
              style={{ borderBottom: fromTo == 'desde' && '4px solid #65A518' }}
              onClick={() => {
                setFromTo('desde');
              }}
            >
              Desde
            </button>
            <button
              className={styles.header_button}
              style={{ borderBottom: fromTo == 'hasta' && '4px solid #65A518' }}
              onClick={() => {
                dateContainer.selectedDateInit && setFromTo('hasta');
              }}
            >
              Hasta
            </button>
          </div>
        </div>
        <div className={styles.body_calendar}>
          <div className={styles.month}>
            <button type="button" className={styles.btn_left} onClick={subtractMonth}>
              <img style={{ transform: 'rotate(180deg)' }} src={arrowRight} alt="arrowRight" />
            </button>
            <span>{`${
              date?.currentMonth ? getMonthById(date.currentMonth.getMonth()).complete : ''
            } ${date?.currentMonth ? date.currentMonth.getFullYear() : ''}`}</span>
            <button type="button" className={styles.btn_right} onClick={addMonth}>
              <img src={arrowRight} alt="arrowRight" />
            </button>
          </div>
          <ol className={styles.days_calendar}>
            <li className={styles.day_name}>Do</li>
            <li className={styles.day_name}>Lu</li>
            <li className={styles.day_name}>Ma</li>
            <li className={styles.day_name}>Mi</li>
            <li className={styles.day_name}>Ju</li>
            <li className={styles.day_name}>Vi</li>
            <li className={styles.day_name}>Sa</li>

            {arrayDays.map(({ numDay, dateOfDay, tooltipDate }, i) => (
              <TooltipCalendar tooltipInfo={tooltipDate} key={i}>
                <li
                  onClick={() => {
                    changeDate(dateOfDay);
                  }}
                  className={styleDays(dateOfDay)}
                >
                  {numDay}
                </li>
              </TooltipCalendar>
            ))}
          </ol>
          <div className={styles.container_buttons}>
            <button
              type="button"
              ref={cancelBtnRef}
              onClick={() => {
                setIsShow(false);
                setDateContainer({
                  ...dateContainer,
                  selectDateInit: dateContainer.currentDateInit,
                  selectedDateEnd: dateContainer.currentDateEnd
                });
              }}
              className={styles.cancel_button}
            >
              Cancelar
            </button>
            <button type="button" ref={okBtnRef} onClick={chooseDate} className={styles.ok_button}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
