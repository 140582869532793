import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './LibertyCustomActionBox.module.scss';
import arrowDownWhite from 'assets/icons/arrowDownWhite.svg';
import { LibertyCustomTooltip } from '../LibertyCustomTooltip/LibertyCustomTooltip';
export const LibertyCustomActionBox = ({
  title,
  options = [{ name: '', action: () => {}, tooltip: null, id: '' }],
  tooltipHeader = null,
  disable = false,
  isLoading = false,
  headerClass = null,
  containerClass = 'action_box_container',
  width = ''
}) => {
  const headerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const headerStyle = useMemo(() => {
    if (headerClass) return headerClass;
    if (disable) return styles.disable;
    return styles.header;
  }, [disable, headerClass]);

  useEffect(() => {
    const closeOptionsBox = (event) => {
      if (!event.composedPath().includes(headerRef.current)) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('click', closeOptionsBox);
    return () => {
      document.body.removeEventListener('click', closeOptionsBox);
    };
  }, [isOpen]);

  return (
    <div className={styles[containerClass]} style={{ width: width }}>
      {tooltipHeader && (
        <LibertyCustomTooltip
          id={tooltipHeader.id}
          tooltipInfo={tooltipHeader.text}
          position={tooltipHeader.position || 'top'}
        />
      )}
      {isLoading ? (
        <div className={styles.container_spinner}>
          <div className={styles.spinner_loader} />
        </div>
      ) : (
        <label
          className={`${styles.base_header} ${headerStyle} ${isOpen && styles.open}`}
          onClick={() => !disable && setIsOpen(!isOpen)}
          ref={headerRef}
          id={tooltipHeader?.id}
        >
          <div className={styles.title_header}>{title}</div>
          <img className={styles.arrow_dark} src={arrowDownWhite} alt="arrowDownWhite" />
        </label>
      )}
      <div className={styles.options_box} style={{ display: isOpen ? 'flex' : 'none' }}>
        {options.map((option, i) =>
          option ? (
            <div key={`${option.name}_${i}_${option.id || ''}`}>
              {option.tooltip && option.id && (
                <LibertyCustomTooltip
                  id={option.id}
                  tooltipInfo={option.tooltip}
                  position="right"
                />
              )}
              <label
                className={`${styles.option} ${option.disable && styles.disable}`}
                onClick={() => !option.disable && option.action()}
                id={option.id}
              >
                <span className={styles.option_text}>{option.name}</span>
              </label>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};
