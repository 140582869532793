import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const fetchLoadAnnulmentOtherBranch = createAsyncThunk(
  'annulmentOtherBranch/fetchLoadAnnulmentOtherBranch',
  async ({ sseguro }) => {
    try {
      const {
        data: { data }
      } = await getRequest(`cancel-data?sseguro=${sseguro}`, BaseTypes.cancellation);
      return data;
    } catch (error) {
      console.error(error);
      return {
        data: null
      };
    }
  }
);
