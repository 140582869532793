import hamburgerMenu from 'assets/icons/hamburger-menu.svg';
import close from 'assets/icons/close.svg';
import emailIcon from 'assets/icons/email.svg';
import userIcon from 'assets/icons/user.svg';
import styles from './LibertyProfileMenuResponsive.module.scss';
import { useState } from 'react';
import { dateToString, parseDateHours } from 'utils/formatDate';
import LibertyButton from '../LibertyButton/LibertyButton';
import { useSession } from 'context/SessionContext';

const LibertyProfileMenuResponsive = ({ name, email, ip, lastConnection, dateToday, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useSession();
  return (
    <div className={styles.container_icon}>
      <img
        src={hamburgerMenu}
        alt="hamburguer"
        onClick={() => {
          setIsOpen(true);
        }}
      />
      {isOpen && (
        <div className={styles.container_info_user}>
          <div className={styles.header_container}>
            <img src={hamburgerMenu} alt="hamburguer" />
            <span className={styles.header_title}> Perfil</span>
            <img
              className={styles.icon_close}
              src={close}
              alt="close"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <div className={styles.principal_info}>
            <div className={styles.container_principal_info}>
              <div className={styles.container_image_info}>
                <div className={styles.icon}>
                  <img src={userIcon} alt="userIcon" />
                </div>
                <div className={styles.container_info}>
                  <span className={styles.title}>Nombre</span>
                  <span className={styles.info}>{name || ''}</span>
                </div>
              </div>
              <div className={styles.container_image_info}>
                <div className={styles.icon}>
                  <img
                    src={emailIcon}
                    alt="emailIcon"
                    style={{
                      width: '16px',
                      height: '17.91px'
                    }}
                  />
                </div>
                <div className={styles.container_info}>
                  <span className={styles.title}>Email</span>
                  <span className={styles.info}>{email || ''}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.secundary_info}>
            <div className={styles.container_info_session}>
              <div className={styles.container_info}>
                <span className={styles.title}>IP</span>
                <span className={styles.info}>{ip}</span>
              </div>
              <div className={styles.container_info}>
                <span className={styles.title}>Hora última conexión</span>
                <span className={styles.info}>{parseDateHours(lastConnection)}</span>
              </div>
              <div className={styles.container_info}>
                <span className={styles.title}>Fecha última conexión</span>
                <span className={styles.info}>{dateToString(lastConnection)}</span>
              </div>
              <div className={styles.container_info}>
                <span className={styles.title}>Fecha actual</span>
                <span className={styles.info}>{dateToday}</span>
              </div>
            </div>
          </div>
          <div className={styles.button_container}>
            <LibertyButton
              id="btn_cerrarsesion"
              type="secondary"
              style={{ marginRight: '0', width: '280px', height: '36px' }}
              onClick={logout}
            >
              Cerrar sesión
            </LibertyButton>
          </div>
          <div className={styles.footer}>
            <span className={styles.item_name}>
              Liberty {new Date().getFullYear()}. Todos los derechos reservados
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default LibertyProfileMenuResponsive;
