import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LibertyButton from 'components/basics/LibertyButton/LibertyButton';
import { Header, LibertyModalBase } from 'components/basics/LibertyModal/components';
import warning from 'assets/icons/WarningRed.svg';
import { URL_OFFICE } from 'common';

import styles from './SessionFinally.module.scss';
interface SessionFinallyProps {
  open: boolean;
  onLogout: () => void;
}

export const SessionFinally = ({ open, onLogout }: SessionFinallyProps) => {
  return (
    <LibertyModalBase open={open} containerClassName={styles.container} onClose={onLogout}>
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
        className={styles.body}
      >
        <Header onClose={onLogout} />
        <Grid container justifyContent="center" alignContent="center" className={styles.grid}>
          <Grid item xs={12}>
            <img src={warning} />
          </Grid>
          <Grid item xs={12}>
            <h1 style={{fontFamily: "hdipro medium"}}>
              Tu sesión ha finalizado.
            </h1>
          </Grid>
        </Grid>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs="auto">
              <LibertyButton
                id="btn_SessionFinally_IniciarSesion"
                component="a"
                className={clsx(styles.buton, 'primary')}
                href={URL_OFFICE}
              >
                Iniciar sesión
              </LibertyButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </LibertyModalBase>
  );
};
